canvas {
  background: #232323;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0 !important;
}

#tsparticles-2 {
  width: 100%;
  height: 100%;
}

#tsparticles-1 {
  width: 100%;
  height: 100%;
}
