$theme-color: #06c739;
$dark-theme-color: #04992c;
$text-color: #ffffff;
$project-container-color: #379137;

.single-project-container {
  position: relative;
  width: 100%;
  display: flex;
  height: 87.5%;
}

.single-project-container-reverse {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  height: 87.5%;
}

.project-container-left {
  position: relative;
  width: 50%;
  height: 100%;
  margin-left: 10%;
  background: linear-gradient(#6cda89, #22c94f, #379137);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: all 0.2s;
}

.project-container-left:hover {
  transform: scale(0.95);
}

.project-container-right {
  position: relative;
  margin-right: 10%;
  width: 50%;
  height: 100%;
  background: linear-gradient(#6cda89, #22c94f, #379137);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  transition: all 0.2s;
}

.project-container-right:hover {
  transform: scale(0.95);
}

.project-img {
  width: 87.5%;
  height: 75%;
}

.project-info-right {
  position: relative;
  color: $text-color;
  top: 8rem;
  margin-left: -4.5rem;
}

.project-info-left {
  position: relative;
  color: $text-color;
  top: 8rem;
  margin-right: -5rem;
}

.single-project-info {
  margin-top: 1rem;
  font-weight: 600;
  font-size: 1.15rem;
}

.single-project-buttons-container {
  display: flex;
  flex-direction: column;
}

#learn-more-button {
  width: 120.75px;
}

#try-app-button {
  width: 85px;
}

.single-project-button {
  text-align: center;
  background-color: transparent;
  border: none;
  height: 35px;
  margin-top: 1.5rem;
  letter-spacing: 0.2rem;
  font-size: 0.75rem;
  font-weight: 600;
  cursor: pointer;
  z-index: 2;
  position: relative;
  color: $text-color;
}

.single-project-button:after {
  content: "";
  position: absolute;
  height: 2px;
  background-color: $dark-theme-color;
  width: 100%;
  bottom: 1px;
  display: block;
  transform-origin: bottom;
  transition: all 0.25s;
  z-index: -1;
}

.single-project-button:hover:after {
  height: 100%;
  position: absolute;
  bottom: 1px;
}

.single-project-button:hover {
  color: black;
}

@media only screen and (max-width: 1250px) {
  .project-container-left {
    width: 80%;
    height: 70%;
  }
  .project-container-right {
    width: 80%;
    height: 70%;
  }
  .project-info-right {
    top: 2.5rem;
    margin-left: -2.5rem;
    margin-right: 1rem;
  }
  .project-info-left {
    top: 2.5rem;
    margin-right: -4.25rem;
    margin-left: 2rem;
  }
  .single-project-button {
    width: 40%;
    height: 2rem;
    margin-top: 1rem;
  }
}
