@import "Canvas.scss";
@import "Landing.scss";
@import "Navbar.scss";
@import "About.scss";
@import "Projects.scss";
@import "SingleProject.scss";
@import "Contact.scss";
@import "Footer.scss";

$background-theme-color: #232323;

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

.App {
  background-color: $background-theme-color;
}
// .react-links {
//   width: 100%;
//   height: 100%;
//   position: relative;
//   z-index: 9;
// }
