$text-color: #ffffff;
$dark-theme-color: #04992c;
$theme-color: #06c739;
$background-theme-color: #232323;

#about-container {
  position: relative;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
}

#about-contents {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90%;
  top: 4rem;
}

#about-header {
  color: $text-color;
  position: relative;
  font-size: 3.5rem;
  font-weight: 700;
  z-index: 999;
}

#about-header:before {
  position: absolute;
  content: "";
  border-bottom: 22px solid $dark-theme-color;
  width: 10rem;
  display: block;
  margin: 0 auto;
  position: relative;
  left: 2.4rem;
  top: 4.4rem;
  z-index: -1;
}

#about-info {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  overflow: hidden;
  margin-top: 3%;
}

#about-intro {
  position: relative;
  color: $text-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15%;
  margin-right: 5%;
  text-align: justify;
  height: 100%;
}

#about-avatar {
  height: 300px;
  width: 300px;
  margin-bottom: 3rem;
}

.cls-1 {
  fill: none;
}

#about-skills {
  display: flex;
  margin-right: 15%;
  margin-left: 5%;
  color: $text-color;
}

.skills-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skill-container {
  height: 100px;
  width: 155px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px transparent;
  margin: 1rem;
  color: $text-color;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.25rem;
  padding: 0.25rem;
  background-image: linear-gradient(
      $background-theme-color,
      $background-theme-color
    ),
    radial-gradient(circle at top, #c7f0bd, #04992c);
  border-radius: 25px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.skill-icon-container {
  position: relative;
  width: 45%;
  height: 60%;
  margin-top: 0.75rem;
}

.skill-name {
  margin-top: 0.25rem;
}

@media only screen and (max-height: 740px) {
  #about-container {
    height: 125vh;
  }
}

@media only screen and (max-height: 655px) {
  #about-container {
    height: 205vh;
  }

  #about-info {
    height: 100%;
    justify-content: center;
    justify-items: center;
    align-items: center;
    flex-direction: column;
  }

  #about-skills {
    margin: 0;
  }
}

@media only screen and (max-width: 1050px) {
  #about-container {
    height: 140vh;
  }

  #about-info {
    height: 100%;
    justify-content: center;
    justify-items: center;
    align-items: center;
    flex-direction: column;
  }

  #about-skills {
    margin: 0;
  }
}

@media only screen and (max-height: 575px) {
  #about-container {
    height: 250vh;
  }

  #about-info {
    height: 100%;
    justify-content: center;
    justify-items: center;
    align-items: center;
    flex-direction: column;
  }

  #about-skills {
    margin: 0;
  }
}
