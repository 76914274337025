$navbar-color: #2e2e2b;
$text-color: #ffffff;
$theme-color: #04992c;

#navbar {
  width: 100%;
  height: 6vh;
  background-color: $navbar-color;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
}

#nav-list-container {
  height: 100%;
  position: absolute;
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin-right: 3rem;
}

.nav-item {
  position: relative;
  top: 50%;
  width: 100%;
  height: 100%;
  margin-right: 1.75rem;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  color: $text-color;
  line-height: 0;

  &:hover {
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: $text-color;
    position: relative;
    z-index: 1;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
      to right,
      $theme-color,
      $theme-color 50%,
      $text-color 50%
    );
    background-size: 200% 100%;
    background-position: 100%;
    transition: all 0.2s ease-in-out;

    &:before {
      display: block;
      content: "";
      width: 0;
      height: 3px;
      bottom: 5px;
      left: 0;
      bottom: -3px;
      z-index: 0;
      position: absolute;
      background: $theme-color;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      background-position: 0%;
      &:before {
        width: 100%;
      }
    }
  }
}
