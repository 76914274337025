$theme-color: #06c739;
$dark-theme-color: #04992c;
$text-color: #ffffff;
$background-theme-color: #232323;

#landing-container {
  position: relative;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
}

#landing-contents {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 40%;
}

#header-1 {
  font-size: 3.5rem;
  color: $text-color;
}

#header-1 span {
  color: $dark-theme-color;
}

#header-2 {
  font-size: 1.5rem;
  color: $text-color;
}

#center {
  width: 180px;
  height: 60px;
  position: relative;
  margin-top: 1rem;
}

#view-work-btn {
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: $background-theme-color;
  border: 2.5px solid $dark-theme-color;
  outline: none;
  transition: 1s ease-in-out;
  border-radius: 6px;

  &:hover div {
    stroke-dashoffset: -480;
  }
}

svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: $background-theme-color;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  stroke-width: 2.5px;
  transition: 1s ease-in-out;
  border-radius: 6px;
}

#view-work-btn:hover svg {
  stroke-dashoffset: -480;
}

#view-work-btn span {
  position: relative;
  color: $dark-theme-color;
  font-size: 18px;
  font-weight: 600;
}

#view-work-btn:hover span {
  letter-spacing: 0.75px;
}
