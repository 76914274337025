$background-theme-color: #232323;
$text-color: #ffffff;
$dark-theme-color: #04992c;
$theme-color: #06c739;
$fail-msg-color: #c70606;

#contact-container {
  padding-top: 7rem;
  position: relative;
  display: block;
  width: 100%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contact-header {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $text-color;
  position: relative;
  font-size: 3.5rem;
  font-weight: 700;
  z-index: 999;
}

#contact-header:before {
  position: absolute;
  content: "";
  border-bottom: 22px solid $dark-theme-color;
  width: 13rem;
  display: block;
  margin: 0 auto;
  position: relative;
  left: 2rem;
  top: 4.4rem;
  z-index: -1;
}

#contact-content {
  position: relative;
  margin-top: 3rem;
  width: 500px;
}

#contact-text {
  color: $text-color;
}

#contact-form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.contact-form-input {
  margin-top: 0.75rem;
  font-size: 1rem;
  padding: 0.75rem;
  border: none;
  background-color: #2e2e2b;
  color: $text-color;
}

#contact-form input {
  height: 1rem;
}

#contact-form textarea {
  height: 7rem;
  resize: none;
}

.contact-form-footer {
  position: relative;
  margin-top: 0.75rem;
  display: flex;
  justify-content: flex-end;
}

#contact-form-submit {
  background-color: transparent;
  border: none;
  height: 1.75rem;
  width: 6.5rem;
  letter-spacing: 0.2rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  z-index: 2;
  position: relative;
  color: $text-color;
}

#contact-form-submit:after {
  content: "";
  position: absolute;
  height: 2px;
  background-color: $dark-theme-color;
  width: 100%;
  bottom: 1px;
  display: block;
  transform-origin: bottom;
  transition: all 0.25s;
  z-index: -1;
}

#contact-form-submit:hover:after {
  height: 100%;
  position: absolute;
  bottom: 1px;
}

#contact-form-footer button:hover {
  color: black;
}

#contact-success-msg {
  color: $dark-theme-color;
}

#contact-fail-msg {
  color: $fail-msg-color;
}

@media only screen and (max-height: 850px) {
  #contact-container {
    height: 80vh;
  }
}

@media only screen and (max-height: 700px) {
  #contact-container {
    height: 90vh;
  }
}

@media only screen and (max-height: 600px) {
  #contact-container {
    height: 110vh;
  }
}
